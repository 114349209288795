










import { Component, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudentHistoryHeader from '@/components/organisms/v3/StudentHistoryHeader.vue'
import StudentAchievement from '@/components/organisms/StudentAchievement.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import { StudentGoal } from '@/models/api/studentGoal'
import StudentGoalHeader from '@/components/organisms/v3/StudentGoalHeader.vue'


@Component({
  components: {
    SidebarSwitcher,
    StudentHistoryHeader,
    StudentAchievement,
    ColoredBox,
    StudentGoalHeader
  },
})
export default class TargetSituation extends Vue {

  private currentGoal: StudentGoal | null = null

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    this.currentGoal = await this.loadCurrentGoal()
    Vue.prototype.$loading.complete()
  }
  private async loadCurrentGoal() {
    const { data } = await Vue.prototype.$http.httpWithToken.get('/v3/studentGoals/current')
    return data
  }
}
